import React, { useState } from 'react'
import GiftControls from './GiftControls'
import Controls from './Controls'
import GiftVideoWrapper from './GiftVideoWrapper2'
import { useEventContext } from '../../../../contexts/EventProvider'
import { updateEvent } from '../../../../../api/app/events'
import { useDispatch } from 'react-redux'
import TheatreHeader from '../../../molecules/TheatreHeader'
import { useRoleContext } from '../../../../contexts/RoleProvider'

export const useGiftInstance = (event) => {
	const dispatch = useDispatch()

	const [giftColor, setGiftColor] = useState(event.giftWrapColor)
	const [resetGift, setResetGift] = useState()

	/** Handler to switch Gift Color */
	const handleSetGiftColor = (color) => {
		/** If new color is different than existing color only  */
		if (giftColor != color) {
			/** Set the new color in local state */
			setGiftColor(color)
			/** Save reset time */
			const reset = new Date().getTime()
			setResetGift(reset)

			/** Prepare new updates for server */
			const eventData = {
				id: event.id,
				giftWrapColor: color,
			}
			/** Save new modifications */
			dispatch(updateEvent(eventData, true))
		}
	}
	/** Return internal state */
	return {
		giftColor,
		resetGift,
		handleSetGiftColor,
	}
}

const Theatre = () => {
	const { event } = useEventContext()
	const { role } = useRoleContext()
	const giftInstance = useGiftInstance(event)

	const showConfetti = event.occasion !== 'memorial' && event.giftWrapColor !== 'none'

	return (
		<>
			<TheatreHeader />

			<GiftControls giftInstance={giftInstance} />

			<GiftVideoWrapper role={role} giftInstance={giftInstance} showConfetti={showConfetti} />

			<Controls />
		</>
	)
}

export default Theatre
