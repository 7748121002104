import React from 'react'
import { deleteMedia } from '../../../../api/app/media'
import { useDispatch } from 'react-redux'
import qs from '../../../../utils/qs'
import {
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

const ModalDeleteThankYouVideo = ({ onClose, isOpen, media }) => {
	// let eventData = { id: event.id, uuid: event.uuid, status: 'archived' }
	const dispatch = useDispatch()

	// Retrieve Private key from URL
	const privateKey = qs('v')

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="md">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={'/assets/images/icons/icon_warning.svg'} alt="Warning" />
						<Heading size="lg" variant="hero" color="dark">
							Delete this thank you video?
						</Heading>
						<Text color="dark">Are you sure you want to delete this thank you video?</Text>
						<Text color="dark">It will no longer be available to view.</Text>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Cancel
						</Button>
						<Button
							onClick={() => {
								dispatch(deleteMedia(media?.id + '?privateKey=' + privateKey))
								onClose()
							}}>
							Yes, I'm sure
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalDeleteThankYouVideo
