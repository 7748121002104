import React, { useRef, useState, useEffect } from 'react'
import { Flex, Button, useDisclosure } from '@chakra-ui/react'
import fullscreen, { fsListeners } from '../../../../organisms/VideoPlayer/libs/fullscreen'
import ModalHidenVideo from '../../../../organisms/ModalHidenVideo'
import configClient from '../../../../../../../client/config.client'
import useEventListener from '../../../../../hooks/useEventListener'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { chakra } from '@chakra-ui/react'
import { PlayArrowRounded, FullscreenRounded, FullscreenExitRounded } from '../../../../atoms/Icon'
import Confetti from '../../../../molecules/Confetti'
import { isIOS, isSafari } from 'react-device-detect'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useAuthContext } from '../../../../../contexts/AuthProvider'

import axios from 'axios'
import { useRoleContext } from '../../../../../contexts/RoleProvider'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
})

const tryCatch = (tryThis) => {
	try {
		tryThis
	} catch (e) {
		console.log('error', e)
	}
}

const GiftWrapIntro = ({ publishedMedia, height, showConfetti, giftInstance }) => {
	const { giftColor, resetGift } = giftInstance
	const { width } = useWindowSize()
	const videoRef = useRef()
	const [src, setSrc] = useState()
	const [wrapped, setWrapped] = useState(true)
	const showPrivateModal = useDisclosure()
	const { role } = useRoleContext()

	//** Send Notifications after opened */
	//** Event data */
	const { event } = useEventContext()
	/** Authed user data */
	const { userId, hasAdminPrivilege } = useAuthContext()

	/** Toggle when "opened" event has been sent to backend (prevents double-send) */
	const [opened, setOpened] = useState(false)
	const onPlay = async () => {
		// send "opened" event to backend (once!) when privateKey exists!
		const isNotCreator = event.creatorId !== userId
		if (isNotCreator && event.privateKey && !opened && !event.contributorsNotified) {
			try {
				instance.post(`/api/v2/events/${event.id}/opened`, {
					privateKey: event.privateKey,
				})
				setOpened(true)
			} catch (e) {
				console.log(`Post to [/api/v2/events/${event.id}/opened] enpoint failed:`, e)
			}
		}
	}

	/** Reset Annimation */
	const reset = () => {
		if (giftColor == 'none' || !giftColor) {
			setSrc(`${publishedMedia?.signedLocationUrl}#t=0.01`)
		} else {
			setSrc(`${configClient.gift_wrap.baseUrl}/videos/${giftColor}.mp4#t=0.01`)
		}
		setWrapped(true)
	}

	/** Change giftwrap if new color is selected */
	useEffect(() => {
		if (giftColor == 'none' || !giftColor) {
			setSrc(`${publishedMedia?.signedLocationUrl}#t=0.01`)
			setWrapped(true)
		} else {
			reset()
		}
	}, [giftColor, resetGift])

	useEffect(() => {
		// Play first video source
		if (!wrapped && videoRef.current && videoRef.current.play) {
			// Play Video
			tryCatch(videoRef.current.play())
		}
	}, [wrapped, src])

	const [poster, setPoster] = useState()
	useEffect(() => {
		if (!src?.includes('giftwrap')) {
			setPoster(`${publishedMedia?.signedThumbnailUrl}`)
		} else {
			setPoster(`${configClient.gift_wrap.baseUrl}/posters/${giftColor}${size}.jpg`)
		}
	}, [])

	/** Fullscreen stuff */
	const [parentFullScreen, setParentFullScreen] = useState(false)
	/** Reference the gift wrapper element */
	const giftRef = useRef()
	const elem = giftRef.current

	/** Handle the fullscreen toggle */
	const handleFullscreen = () => {
		if (fullscreen.enabled) {
			if (fullscreen.isFullscreen) {
				fullscreen.exit()
				setParentFullScreen(false)
			} else {
				fullscreen.request(elem)
				setParentFullScreen(true)
			}
		}
	}

	/** Listen for document fullscreen changed */
	const onDocumentFullscreen = (e) => {
		if (fullscreen.isFullscreen == elem && parentFullScreen && giftColor != 'none') {
			fullscreen.exit()
		}
	}
	/** Add an change listener for each defined api */
	const element = parentFullScreen ? videoRef.current : null
	fsListeners.forEach((listener) => {
		useEventListener(listener, onDocumentFullscreen, element)
	})

	/** Change poster resolution based on screen width */
	const size = width > 1280 ? '_1920x1080' : '_1280x720'

	const videoElement = (
		<chakra.video
			ref={videoRef}
			preload="auto"
			// autoPlay={!src?.includes('giftwrap') && parentFullScreen} // play second video when in fullscreen
			// muted={src?.includes('giftwrap')}
			controlsList={src?.includes('giftwrap') ? ['nodownload', 'nofullscreen'] : ['nodownload']}
			width={'auto'}
			height={`${height}px`}
			playsInline={!isIOS}
			controls={!src?.includes('giftwrap')}
			poster={poster}
			onEnded={() => {
				if (src?.includes('giftwrap')) {
					// Second video source
					setSrc(publishedMedia?.signedLocationUrl)
					setPoster(`${publishedMedia?.signedThumbnailUrl}`)
				} else {
					reset()
				}
			}}
			onError={(error) => console.error(error)}
			onContextMenu={(e) => {
				e.preventDefault()
			}}
			onClick={
				src?.includes('giftwrap')
					? () => {
							if (!event.isPrivate || role == 'recipient' || role == 'creator' || hasAdminPrivilege) {
								setWrapped(false), onPlay()
							} else {
								showPrivateModal.onOpen()
							}
					  }
					: () => {}
			}
			src={src}
		/>
	)

	const Video = () => {
		return videoElement
	}

	return (
		<>
			<Flex height={parentFullScreen ? '100%' : height ? `${height}px` : 'auto'} borderRadius={[0, 'xl']}>
				<Flex id="main-video-wrapper" ref={giftRef} height={parentFullScreen ? '100%' : `${height}px`}>
					{src && <>{isSafari || isIOS ? <Video /> : videoElement}</>}

					{wrapped && (
						<>
							<Button
								pos="absolute"
								transform="translate(-50%, -50%)"
								top="50%"
								left="50%"
								aria-label="Button"
								boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.5)"
								w={['3rem', '5rem']}
								h={['3rem', '5rem']}
								zIndex="2"
								backgroundColor="white"
								onClick={() => {
									if (
										!event.isPrivate ||
										role == 'recipient' ||
										role == 'creator' ||
										hasAdminPrivilege
									) {
										setWrapped(false), onPlay()
									} else {
										showPrivateModal.onOpen()
									}
								}}
								_hover={{
									backgroundColor: 'rgb(205, 231, 255)',
								}}
								_active={{
									backgroundColor: 'rgb(205, 231, 255)',
								}}>
								<PlayArrowRounded w={['2rem', '4rem']} h={['2rem', '4rem']} color="#2E76DB" />
							</Button>
							{giftColor != 'none' && !isIOS && (
								<Button
									position="absolute"
									variant="light"
									color="light"
									backgroundColor="whiteAlpha.500"
									h="auto"
									p=".5rem"
									right="1rem"
									bottom={!src?.includes('giftwrap') ? '6rem' : '1rem'}
									zIndex="1"
									onClick={() => handleFullscreen()}>
									{parentFullScreen ? <FullscreenExitRounded /> : <FullscreenRounded />}
								</Button>
							)}
						</>
					)}

					<Confetti fire={!wrapped && showConfetti} id={'main-video-wrapper'} />
				</Flex>
			</Flex>

			<link
				rel="preload" // prefetch not supported fully in safari, but preload is see: https://caniuse.com/?search=preload
				as={'video'}
				type={'video/mp4'}
				href={publishedMedia?.signedLocationUrl}
				crossOrigin="anonymous"
			/>

			{giftColor && giftColor != 'none' && (
				<link
					rel="preload" // prefetch not supported fully in safari, but preload is see: https://caniuse.com/?search=preload
					as={'video'}
					type={'video/mp4'}
					href={`${configClient.gift_wrap.baseUrl}/videos/${giftColor}.mp4`}
					crossOrigin="anonymous"
				/>
			)}
			<ModalHidenVideo isOpen={showPrivateModal.isOpen} onClose={showPrivateModal.onClose} />
		</>
	)
}

export default GiftWrapIntro
