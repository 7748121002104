import React, { useState, useEffect } from 'react'
import { Box, Image } from '@chakra-ui/react'
import { useEventContext } from '../../../../../contexts/EventProvider'
import giftWraps from './gift-wraps'
import AliceCarousel from 'react-alice-carousel'
import config from '../../../../../../../client/config.client'
import CheckExMark from '../../../../atoms/CheckExMark'
import { SliderArrow } from '../../../../atoms/SliderArrow'
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '../../../../atoms/Icon'
import { isDesktop } from 'react-device-detect'
import findIndex from 'lodash.findindex'
/**
 * Cache default Standard Wrap
 * */
const defaultStandardWrap = 'new_sprinkle_purple'

const GiftControls = ({ giftInstance }) => {
	/** Break up instance */
	const { giftColor, handleSetGiftColor } = giftInstance

	/** Retrieve theme template ID */
	const {
		event: { themeTemplateId },
	} = useEventContext()

	const [thumbIndex, setThumbIndex] = useState(null)

	useEffect(() => {
		if (!giftColor) {
			const existingWrap = giftWraps.find(
				(obj) => obj.type == 'theme' && obj.themeTemplateId && obj.themeTemplateId == themeTemplateId
			)
			const uniqueThemeWrap = existingWrap ? existingWrap.id : defaultStandardWrap
			handleSetGiftColor(uniqueThemeWrap)
		}
	}, [])
	useEffect(() => {
		if (giftColor) {
			let i = giftWraps.findIndex((t) => t.id == giftColor)
			setThumbIndex(i)
		}
	}, [])

	// Not sure why this is needed but it's in the npm docs
	const handleDragStart = (e) => e.preventDefault()

	const thumbItems = giftWraps.map((theme, i) => {
		const path = `${config.gift_wrap.baseUrl}/thumbnails/${theme.id ? theme.id : 'none'}`
		const path1x = `${path}.png`
		const path2x = `${path}@2x.png`

		/** Change poster resolution based on screen width */
		// const size = width > 1280 ? '_1920x1080' : '_1280x720'
		// const pathPoster = `${config.gift_wrap.baseUrl}/posters/${theme.id}${size}.jpg`
		// const pathVideo = `${config.gift_wrap.baseUrl}/videos/${theme.id}.mp4`

		const isSelected = theme.id == giftColor

		return (
			<Box
				display="flex"
				alignItems="center"
				position="relative"
				cursor="pointer"
				key={i}
				height="3.5rem"
				width="3.5rem"
				title={theme?.name}
				onClick={(e) => {
					e.preventDefault()
					handleSetGiftColor(theme.id)
					// if (i > 5) {
					// 	// We dont want to do that if we're clicking on one of the first 6 items, to keep the natural position
					// 	setThumbIndex(i - 1) // Only trick the current position if not desktop (as we dont have issue on desktop so better to keep initial behavior)
					// }
				}}
				onDragStart={handleDragStart}
				transition="all 0.3s ease-in-out">
				{isSelected && (
					<Box position="absolute" left="4px" top="8px">
						<CheckExMark render={true} check={true} scale={0.25} color={'#0a2239'} />
					</Box>
				)}
				<Image
					src={path1x}
					srcSet={`${path2x} 2x, ${path1x} 1x`}
					borderRadius="8px"
					transition="all 0.3s ease-in-out"
					height={isSelected ? '3rem' : '3rem'}
					width={isSelected ? '3rem' : '3rem'}
					maxHeight={isSelected ? '3rem' : '3rem'}
					maxWidth={isSelected ? '3rem' : '3rem'}
					boxShadow={isSelected ? '0 0 0 4px var(--vidday-colors-pink-500)' : 'none'}
					alt={theme.name}
				/>
				{/* <chakra.img  src={pathPoster} crossOrigin="anonymous" /> */}
			</Box>
		)
	})

	const before = thumbItems.length > 2 && {
		pointerEvents: 'none',
		content: '""',
		width: '.5rem',
		marginLeft: ['0', '-6px'],
		height: '100%',
		position: 'absolute',
		bottom: '0',
		zIndex: '3',
		left: '0',
		background: 'linear-gradient( -90deg, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 100% )',
	}
	const after = thumbItems.length > 2 && {
		pointerEvents: 'none',
		content: '""',
		width: '1rem',
		marginRight: '-3px',
		height: '100%',
		position: 'absolute',
		bottom: '0',
		zIndex: '0',
		right: '0',
		background: 'linear-gradient( 90deg, rgba(242,245,246,0) 0%, rgba(242,245,246,1) 75% )',
	}

	// Carousel ref
	let Carousel

	return (
		<Box
			position="relative"
			mt="1rem"
			mb=".5rem"
			minH="3.5rem"
			mx={['-1rem', '0']}
			__css={{
				'.alice-carousel__stage': {
					padding: ['0 0 0 1rem', '0'], //'0 3px 0'],
					//margin: ['0', '0 -3px 0'],
				},
				'.alice-carousel__wrapper': {
					width: 'calc(100% + 6px)',
					paddingLeft: '6px !important',
					marginLeft: '-6px !important',
				},
			}}
			_before={before}
			_after={after}>
			<SliderArrow
				direction="left"
				aria-label="Previous"
				icon={<ArrowBackIosNewRounded ml="-.125rem" w="1rem" h="1rem" color="link" />}
				onClick={() => Carousel.slidePrev()}
			/>

			<SliderArrow
				direction="right"
				aria-label="Next"
				icon={<ArrowForwardIosRounded mr="-.125rem" w="1rem" h="1rem" color="link" />}
				onClick={() => Carousel.slideNext()}
			/>

			<AliceCarousel
				ref={(el) => (Carousel = el)}
				items={thumbItems}
				activeIndex={thumbIndex}
				autoWidth
				infinite={true}
				disableDotsControls
				disableButtonsControls
				touchMoveDefaultEvents={false}
				swipeDelta={5}
				mouseTracking={false}
				touchTracking
			/>
		</Box>
	)
}

export default GiftControls
