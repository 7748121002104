const giftWraps = [
	{
		name: 'None',
		id: 'none',
		// type: 'standard',
	},
	{
		name: 'New Sprinkle Purple',
		id: 'new_sprinkle_purple',
		// type: 'standard',
	},
	{
		name: 'New Navy Blue',
		id: 'new_navy_blue',
		// type: 'standard',
	},
	{
		name: 'New Hearts Pink',
		id: 'new_hearts_pink',
		// type: 'standard',
	},
	{
		name: 'New Black White',
		id: 'new_black_white',
		// type: 'standard',
	},
	{
		name: 'New Gold Red',
		id: 'new_gold_red',
		// type: 'standard',
	},
	{
		name: 'New Bluearrow Green',
		id: 'new_bluearrow_green',
		// type: 'standard',
	},
	{
		name: 'Old Blue Pink',
		id: 'pink_blue',
		// type: 'standard',
	},
	{
		name: 'Old Blue Blue',
		id: 'blue_blue',
		// type: 'standard',
	},
	{
		name: 'Old Pink Purple',
		id: 'purple_pink',
		// type: 'standard',
	},
	{
		name: 'Old Purple Pink',
		id: 'pink_purple',
		// type: 'standard',
	},
	{
		name: 'Old Blue Green',
		id: 'green_blue',
		// type: 'standard',
	},
	{
		name: 'Old Black White',
		id: 'white_black',
		// type: 'standard',
	},
	{
		name: 'Burst of Fun',
		id: 'burst-of-fun',
		// type: 'theme',
		themeTemplateId: 'burst-of-fun',
	},
	{
		name: 'Floral',
		id: 'floral',
		// type: 'theme',
		themeTemplateId: 'floral-v2',
	},
	{
		name: 'Gift Wrap',
		id: 'gift-wrap',
		// type: 'theme',
		themeTemplateId: 'gift-wrap-v1',
	},
	{
		name: 'Wildflower Meadow',
		id: 'wildflower-meadow',
		// type: 'theme',
		themeTemplateId: 'wildflower-meadow',
	},
	{
		name: 'Fossil Fun',
		id: 'fossil-fun',
		// type: 'theme',
		themeTemplateId: 'fossil-fun-v1',
	},
	{
		name: 'Celestial Celebration',
		id: 'celestial-celebration',
		// type: 'theme',
		themeTemplateId: 'celestial-celebration',
	},
	{
		name: 'Adorable',
		id: 'adorable',
		// type: 'theme',
		themeTemplateId: 'adorable-v1',
	},
	{
		name: 'Art Deco',
		id: 'art-deco',
		// type: 'theme',
		themeTemplateId: 'art-deco-v1',
	},
	{
		name: 'School Spirit',
		id: 'school-spirit',
		// type: 'theme',
		themeTemplateId: 'school-spirit-v1',
	},
]
export default giftWraps
