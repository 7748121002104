import React from 'react'
import { VStack, Text, Button, Heading, Image, HStack, Divider, LightMode } from '@chakra-ui/react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import { useNavigate } from 'react-router-dom'
import redirectToNextIfWindow from '../../../utils/redirectToNextIfWindow'

const ModalHidenVideo = ({ onClose, isOpen }) => {
	const navigate = useNavigate()
	const img = useImageGenerator({
		path: '/assets/images/graphics/modal-hide-video',
		ext: 'png',
	})

	return (
		<LightMode>
			<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
				<ModalOverlay />
				<ModalContent>
					{onClose && <ModalCloseButton />}
					<ModalBody>
						<VStack textAlign="center" spacing="1rem">
							<Image src={img.src} srcSet={img.srcset} alt="Hide Your Video" boxSize="64px" />
							<Heading as="h3" size="lg" variant="hero">
								Want to watch this video?
							</Heading>
							<Text>
								<strong>This video is set to private</strong> &mdash; you can contact the creator and
								ask them to make this video viewable.
							</Text>
							<Divider maxW="60px" />
							<Text>
								<small>Are you the creator?</small>
							</Text>
							<Button
								variant="link"
								size="sm"
								mt="0 !important"
								onClick={() => {
									redirectToNextIfWindow('/auth/login')
								}}>
								Log In
							</Button>
						</VStack>
					</ModalBody>
				</ModalContent>
			</Modal>
		</LightMode>
	)
}

export default ModalHidenVideo
