import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDisclosure, Menu, MenuList, MenuItem, Portal, MenuButton, DarkMode } from '@chakra-ui/react'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import ModalDeleteThankYou from '../../../organisms/ModalDeleteThankYouVideo'
import useMediaTools from '../../../pages/EditMedia/hooks/useMediaTools'
import { MoreVertRounded, DeleteRounded, LoopRounded, DownloadRounded } from '../../../atoms/Icon'
import { NavCardButton } from '../../../atoms/NavigationCard'

const ThankYouDropdownMenu = ({ video, role }) => {
	const { uuid } = useParams()
	const { isImpersonated } = useAuthContext()
	const handlers = useEventHandlersV2(uuid)
	const modal = useDisclosure()
	const location = useLocation()
	const navigate = useNavigate()

	const upload_handler = `${handlers.public_upload_thankyou}${location.search}`
	/** Media handling tools */
	const { handleReEncode } = useMediaTools(video)

	/** Get download link */
	const handleDownloadMedia = () => {
		if (video.signedDownloadLocationUrl) {
			window.location.href = video.signedDownloadLocationUrl
		}
	}

	if (role == 'creator' || role == 'recipient') {
		return (
			<>
				<Menu colorMode={role == 'creator' ? 'light' : 'dark'}>
					<MenuButton
						mr="0.5rem"
						padding="0 !important"
						aria-label="See more options"
						size="md"
						onClick={(e) => {
							e.stopPropagation()
						}}>
						<MoreVertRounded />
					</MenuButton>
					<Portal>
						<MenuList onClick={(e) => e.stopPropagation()}>
							{isImpersonated && (
								<MenuItem icon={<LoopRounded color="disabled" />} onClick={handleReEncode}>
									ReEncode/Resend
								</MenuItem>
							)}

							<MenuItem icon={<DownloadRounded color="disabled" />} onClick={handleDownloadMedia}>
								Download
							</MenuItem>

							{role == 'recipient' && (
								<MenuItem
									icon={<LoopRounded color="disabled" />}
									onClick={() => navigate(upload_handler)}>
									Replace
								</MenuItem>
							)}

							<MenuItem icon={<DeleteRounded color="disabled" />} onClick={modal.onOpen}>
								Delete
							</MenuItem>
						</MenuList>
					</Portal>
				</Menu>
				<ModalDeleteThankYou isOpen={modal.isOpen} onClose={modal.onClose} media={video} />
			</>
		)
	}

	return <></>
}

export default ThankYouDropdownMenu
