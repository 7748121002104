import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	encodeMedia,
	regenerateImage,
	updateMedia,
	setMediaToOccupied,
	unArchiveMedia,
	duplicateMedia,
} from '../../../../../api/app/media'
import { history } from '../../../../../history'

const useMediaTools = (data) => {
	const [fixing, setFixing] = useState(false)
	const [encodePercentage, setEncodePercentage] = useState(0)

	/** Retrieve dispatch method */
	const dispatch = useDispatch()

	/** Callback handler to update the mute audio */

	const handleReEncode = useCallback(() => {
		if (data) setEncodePercentage(0)
		setFixing(true)
		dispatch(encodeMedia(data))
		//location.reload()
	}, [data])

	const handleRegenerateImage = useCallback(() => {
		if (data) setFixing(true)
		const withCroppedData = { ...data, croppedLocation: data.location, occupied: true }
		dispatch(regenerateImage({ data: withCroppedData }))
		//location.reload()
	}, [data])

	const handleRestoreImage = useCallback(() => {
		if (data) setFixing(true)
		dispatch(regenerateImage())
	}, [data])

	const handleDuplicateMedia = useCallback(() => {
		dispatch(duplicateMedia({ id: item.id }))
		const hash = data ? '#' + data.id : ''
		history.push(`/event/${uuid}${hash}`)
	}, [data])

	const handleUnArchiveMedia = useCallback(() => {
		if (data) setFixing(true)
		dispatch(unArchiveMedia({ id: data.id }))
	}, [data])

	const handleSetToOccupied = useCallback(() => {
		if (data) setFixing(true)
		dispatch(setMediaToOccupied({ id: data.id }))
	}, [data])

	const handleResetOccupied = useCallback(() => {
		if (data) setFixing(false)
		dispatch(updateMedia({ id: data.id, occupied: false }, false))
	}, [data])

	/** Return state, state updates methods, and handlers */
	return {
		fixing,
		setFixing,
		encodePercentage,
		setEncodePercentage,
		handleReEncode,
		handleRegenerateImage,
		handleRestoreImage,
		handleDuplicateMedia,
		handleUnArchiveMedia,
		handleResetOccupied,
		handleSetToOccupied,
	}
}

export default useMediaTools
