import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDisclosure, Box, Image, useStyles, Spinner, Flex } from '@chakra-ui/react'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../atoms/NavigationCard'
import ThankYouDropdownMenu from './src/ThankYouDropdownMenu'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { history } from '../../../../history'
import { useEventHandlersV2 } from '../../../hooks/useEventHandlers'
import ModalVideo from '../../organisms/ModalVideo'
import PropTypes from 'prop-types'
import { PlayArrowRounded, VideoCallRounded } from '../../atoms/Icon'

const CustomNavCardCover = ({ path }) => {
	// Custom implementation of the cover
	const styles = useStyles()

	if (path !== 'null' && path !== 'undefined') {
		return (
			<Box as="figure" __css={styles.cover} me="-1rem">
				<Image
					borderRadius="4rem"
					padding="0.75rem"
					h="80px"
					w="80px"
					minH="80px"
					minW="80px"
					src={path}
					sx={{ objectFit: 'cover', objectPosition: 'center center' }}
				/>
			</Box>
		)
	} else {
		return (
			<Flex h="80px" w="80px" mr="-1rem" alignItems="center" justifyContent="center">
				<Spinner thickness="4px" speed="0.45s" size="lg" color="white.900" />
			</Flex>
		)
	}
}

CustomNavCardCover.propTypes = {
	path: PropTypes.string,
}

CustomNavCardCover.defaultProps = {
	path: null,
}

const ThankYouSelector = ({ event, video, role, ...rest }) => {
	const videoModal = useDisclosure()
	const recipients = useEventRecipients()
	const handlers = useEventHandlersV2(event.uuid)

	const handleUploadVideo = () => {
		history.push(`${handlers.public_upload_thankyou}?v=${event.privateKey}`)
	}
	const handlePlayVideo = () => {
		videoModal.onOpen()
	}

	/**
	 * Launch thank you video if hash is from notifications
	 */
	const location = useLocation()
	useEffect(() => {
		if (location.hash == '#play-thank-you' && !videoModal.isOpen) {
			handlePlayVideo()
			history.push(location.pathname) // clear hash
		}
	}, [location])

	let title = 'Thank Everyone'
	let description = 'Record a message'
	let thumbnail = '/assets/images/graphics/navigation-cards/vidday-thankyou-video'
	let button = 'Record Message'
	let onClick
	/**
	 * Update content once a video is available.
	 */
	if (video) {
		title = 'Thank You'
		description = 'Play your message'
		thumbnail = `${video.signedThumbnailUrl}`
		button = 'Play Message'
		onClick = handlePlayVideo
	} else {
		if (role != 'public') {
			onClick = handleUploadVideo
		}
	}

	/**
	 * For the public, let's tweak the content a bit
	 */
	if (role == 'public' || role == 'creator') {
		title = 'Thank You!'
		description = `A message from ${recipients.firstname}`
	}

	/**
	 * Only display Thank You Card if we have a video
	 * or if it's the recipient.
	 */
	if (role == 'recipient' || role == 'creator' || video) {
		return (
			<div id="play-thank-you">
				<NavCard
					{...rest}
					onClick={onClick}
					variant={role == 'recipient' || role == 'public' ? 'outline' : null}>
					{video && <CustomNavCardCover path={thumbnail} />}
					{!video && <NavCardCover path={thumbnail} ext="png" />}
					<NavCardContent title={title} description={description} />
					<NavCardActions>
						{video && (role == 'recipient' || role == 'creator') && (
							<ThankYouDropdownMenu video={video} role={role} />
						)}
						<NavCardButton aria-label={button} size="md" onClick={onClick}>
							{!video && role == 'recipient' ? <VideoCallRounded /> : <PlayArrowRounded />}
						</NavCardButton>
					</NavCardActions>
				</NavCard>
				<ModalVideo
					size="2xl"
					autoPlay
					path={video?.signedLocationUrl || null}
					isOpen={videoModal.isOpen}
					onClose={videoModal.onClose}
				/>
			</div>
		)
	}
	return <></>
}

ThankYouSelector.propTypes = {
	event: PropTypes.object.isRequired,
	video: PropTypes.object,
	role: PropTypes.string,
}

export default ThankYouSelector
