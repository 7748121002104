import React, { useState, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box, AspectRatio, Container, Text, Spinner, VStack, Image } from '@chakra-ui/react'
import GiftVideoIntro from '../GiftWrapIntro2'
import usePublishedMedia from '../../../../../hooks/usePublishedMedia'
import { restorePublishedMedia } from '../../../../../../api/app/media'

const GiftVideoWrapper = ({ role, giftInstance, showConfetti }) => {
	const dispatch = useDispatch()
	/** Retrieve published media */
	const publishedMedia = usePublishedMedia()
	/** Hold Ref for the Video Wrapper container */
	const containerRef = useRef(null)
	/** Adjust height of the video element */
	const [height, setHeight] = useState()

	const viewport = useSelector((s) => s.environment.viewport, shallowEqual)
	/** Adjust height once Ref becomes available */
	useEffect(() => {
		if (containerRef.current) {
			const width = containerRef.current.offsetWidth
			const height = containerRef.current.offsetHeight
			setHeight(width > height ? (width / 16) * 9 : 'auto')
		}
	}, [containerRef, viewport])

	/** Trigger a restore of published media if it is in glacier and not yet restoring */
	useEffect(() => {
		if (publishedMedia?.inGlacierStorage && !publishedMedia.isRestoring) {
			dispatch(restorePublishedMedia({ id: publishedMedia.id }))
		}
	}, [publishedMedia])

	/** Archived View */
	const ArchivedView = () => {
		return (
			<AspectRatio
				ratio={[1 / 1, 16 / 9]}
				sx={{
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
				}}>
				<Container centerContent maxWidth="full" bg="white">
					<Image
						src="/assets/images/graphics/vidday-video-vault.png"
						boxSize={['100px', '200px']}
						alt="Vidday Video Vault"
					/>
					<Text
						textAlign="center"
						fontSize="lg"
						fontWeight="bold"
						lineHeight="24px"
						textTransform="capitalized"
						color="gray.900">
						We’re Getting The Video Out of the Vault
					</Text>
					<Text paddingTop="4" textAlign="center" color="gray.900" maxWidth={'400px'}>
						{role == 'creator' || role == 'admin' ? (
							<>
								The video has been safely archived. It’s now being retrieved, which could take 1 to 3
								hours.
								<strong> We'll send you an email as soon as it's ready —</strong> thank you for your
								patience!
							</>
						) : (
							<>
								The video was safely archived. It’s now being retrieved, which could take 1 to 3 hours.
								Thank you for your patience!
							</>
						)}
					</Text>
					<Box mt={[3, 6]}>
						<Spinner size="lg" color="gray.900" />
					</Box>
				</Container>
			</AspectRatio>
		)
	}

	return (
		<Box
			mb="1rem"
			width="100%"
			height="auto"
			minHeight={[height ? height + 'px' : '215px', height ? height + 'px' : '558px']}
			borderRadius="xl"
			overflow="hidden"
			position="relative"
			ref={containerRef}
			bg="black"
			sx={{
				'& video': {
					outline: 'none',
					height: '100%',
					width: '100%',
					display: 'flex',
				},
			}}>
			<>
				{!publishedMedia ? (
					<VStack
						height={[height ? height + 'px' : '215px', height ? height + 'px' : '558px']}
						display="flex"
						alignItems="center"
						justifyContent="center">
						<Text color="white">Loading...</Text>
						<Spinner color="white" />
					</VStack>
				) : (
					<>
						{publishedMedia?.inGlacierStorage || publishedMedia?.isRestoring ? (
							<ArchivedView />
						) : (
							<>
								{giftInstance && (
									<GiftVideoIntro
										publishedMedia={publishedMedia}
										showConfetti={showConfetti}
										giftInstance={giftInstance}
										height={height}
									/>
								)}
							</>
						)}
					</>
				)}
			</>
		</Box>
	)
}

export default GiftVideoWrapper
