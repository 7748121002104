export default function qs(name) {
	if (typeof window !== 'undefined') {
		var query = window.location.search.substring(1) // Remove question mark
		var parameters = query.split('&')

		for (var i = 0; i < parameters.length; i++) {
			var pair = parameters[i].split('=')

			if (pair[0] == name) {
				return pair[1]
			}
		}
	}

	return null
}
